"use client";

import Script from "next/script";
import { clientEnv } from "@/config/env/client-env";
const MicrosoftClarity = () => {
  return <Script id="microsoft-clarity-init" strategy="afterInteractive" dangerouslySetInnerHTML={{
    __html: `
                  (function(c,l,a,r,i,t,y){
                          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                      })(window, document, "clarity", "script", "${clientEnv.MICROSOFT_CLARITY_ID}");
                `
  }} data-sentry-element="Script" data-sentry-component="MicrosoftClarity" data-sentry-source-file="microsoft-clarity.tsx" />;
};
export default MicrosoftClarity;